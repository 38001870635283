<template>
  <div class="flex justify-between items-center w-full px-20 py-3 bg-white">
    <a href="/" class="font-bold text-blue-600 text-3">TTSaver</a>
    <div class="flex items-center">
      <div class="flex items-center" v-if="!!userInfo">
        <div class="flex items-center">
          <div class="mr-2">{{ i18n.t('welcome') }}</div>
          <el-dropdown :hide-on-click="true">
            <div class="el-dropdown-link flex items-center" style="outline:none">
              <div>{{ userInfo.email }}</div>
              <el-icon class="text-blue-400 ml-1 h-full"><ArrowDown ></ArrowDown></el-icon>
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click="goToOrdersPage">
                  {{ i18n.t('order') }}
                </el-dropdown-item>
                <el-dropdown-item @click="logout">
                  {{ i18n.t('sign_out') }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
        <el-dropdown :hide-on-click="true" class=ml-6>
          <div class="el-dropdown-link flex items-center" style="outline:none">
            <div>{{ i18n.t('price') }}</div>
            <el-icon class="text-blue-400 ml-1 h-full"><ArrowDown ></ArrowDown></el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <a :href="`/products?pay_type=alipay`">支付宝</a>
              </el-dropdown-item>
              <!-- <el-dropdown-item>
                <a :href="`/products?pay_type=paypal`">Paypal</a>
              </el-dropdown-item> -->
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <el-link type="default" id="download-tasks-link" class="ml-6" href="/download_tasks">{{ i18n.t('task') }}</el-link>
        <el-link type="default"  class="ml-6" href="mailto:ttsaver@126.com?subject=[建议与反馈]">{{ i18n.t('contact') }}</el-link>
      </div>
      
      <div v-else>
        <el-button @click="login" link type="primary">{{ i18n.t('login') }}</el-button>
      </div>
      <el-dropdown :hide-on-click="true" class="ml-6">
        <div class="el-dropdown-link flex items-center" style="outline:none">
          <div>{{ localeOptions[locale] }}</div>
          <el-icon class="text-blue-400 ml-1 h-full"><ArrowDown /></el-icon>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item v-for="l in Object.keys(localeOptions)" :key="l">
              <a :href="`${path}?locale=${l}`">{{ localeOptions[l] }}</a>
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>


<script setup>
  import { ref, onMounted } from 'vue'
  const userInfo = ref(null)
  const props = defineProps(['i18n', 'locale'])
  const emit = defineEmits(['showLoginDialog'])
  const path = window.location.pathname

  const localeOptions = {
    cn: '中文',
    en: 'English',
  }
  const getUserInfo = () => {
    const node = document.getElementById('data-store')
    if (!node) return

    const data = node.dataset.store
    if (!data) return

    const json = JSON.parse(data)
    userInfo.value = json.user_info
  }

  const login = () => {
    console.log("press login")
    emit('showLoginDialog')
  }

  const logout = () => {
    location.href = '/logout'
  }

  const shine = () => {
    const link = document.querySelector('#download-tasks-link > span')
    link.classList.add('animate-bounce', 'font-bold', 'text-sky-600')
  }

  const goToOrdersPage = () => {
    window.location.href = '/orders'
  }

  defineExpose({
    shine
  });

  onMounted(() => {
    getUserInfo()
  })
  
</script>
<style scoped>
  .el-dropdown {
    line-height: inherit;
  }
</style>