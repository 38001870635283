<template>
  <template v-if="!!orderInfo.error">
    <el-empty description="本来无一物，何处惹尘埃。" />
  </template>
  <template v-else>
    <qrcode-vue :value="orderInfo.url" :size="size" level="H" />
    <div class="mt-3">请使用<span class="text-sky-700 font-bold">支付宝</span>扫码支付</div>
  </template>
</template>

<script setup>
  import { ref, onMounted } from 'vue'
  import QrcodeVue from 'qrcode.vue'
  const orderInfo = ref({})
  const size = ref(200)
  const getOrderInfo = () => {
    const node = document.getElementById('data-store')
    if (!node) return

    const data = node.dataset.store
    if (!data) return

    const json = JSON.parse(data)
    orderInfo.value = json.order_info
  }

  const checkStatus = async () => {
    let count = 0
    const interval = setInterval(async () => {
      if (count >= 120) {
        clearInterval(interval)
      } else {
        const res = await fetch(`/orders/${orderInfo.value.no}/get_status`)
        const data = await res.json()
        if (data.code === 200) {
          if (data.status === 'paid') {
            window.location.href = '/?order_paid=1'
          } else {
            count += 1
          }
        } else {
          clearInterval(interval)
        }
      }
    }, 1000)
  }

  onMounted(async () => {
    getOrderInfo()
    await checkStatus()
  })
</script>