<template>
  <div>
    <el-dialog v-model="dialogVisible" :title="dialogTitle" :width="dialogWidth" @close="resetForm">
      <template v-if="dialogType === 'login'">
        <el-form ref="loginForm" :model="loginForm" label-width="80px">
          <el-form-item :label="i18n.t('email')">
            <el-input v-model="loginForm.email" :placeholder="i18n.t('input_email')"></el-input>
          </el-form-item>
          <el-form-item :label="i18n.t('password')">
            <el-input v-model="loginForm.password" type="password" :placeholder="i18n.t('input_password')"></el-input>
          </el-form-item>
          <el-form-item label="">
            <div class="text-red-500">{{ errorMessage }}</div>
          </el-form-item>

          <el-form-item label="">
            <el-button class="w-full" type="primary" @click="login">{{ i18n.t('login') }}</el-button>
          </el-form-item>

          <el-form-item label="">
            <div class="flex items-center">
              <div>{{ i18n.t('no_account') }}？</div>
              <el-button type="primary" @click="showRegisterDialog" link>{{ i18n.t('sign_up') }}</el-button>
            </div>
          </el-form-item>
        </el-form>

        <el-divider />

        <div class="flex justify-center">
          <GoogleLogin :callback="callback"/>
        </div>
        
        <!-- <div slot="footer" class="dialog-footer flex justify-center">
          <el-button type="primary" @click="login">登录</el-button>
        </div> -->
        
      </template>

      <template v-else-if="dialogType === 'register'">
        <el-form ref="registerForm" :model="registerForm" label-width="80px">
          <el-form-item :label="i18n.t('email')">
            <el-input v-model="registerForm.email" :placeholder="i18n.t('input_email')"></el-input>
          </el-form-item>
          <el-form-item :label="i18n.t('validation_code')">
            <div class="flex items-center justify-between">
              <div class="grow-0 w-1/2">
                <el-input v-model="registerForm.validation_code" :placeholder="i18n.t('input_validation_code')"></el-input>
              </div>
              <el-button class="grow-0" @click="sendValidationCode" type="primary" :disabled="!canSendValidationCode">{{ sendValidationCodeText }}</el-button>
            </div>
          </el-form-item>
          <el-form-item :label="i18n.t('password')">
            <el-input v-model="registerForm.password" type="password" :placeholder="i18n.t('input_password')"></el-input>
          </el-form-item>
          <el-form-item :label="i18n.t('password_confirmation')">
            <el-input v-model="registerForm.password_confirmation" type="password" :placeholder="i18n.t('input_password_confirmation')"></el-input>
          </el-form-item>
          <el-form-item label="">
            <div class="text-red-500">{{ errorMessage }}</div>
          </el-form-item>

          <el-form-item label="">
            <el-button class="w-full" type="primary" @click="register">{{ i18n.t('sign_up') }}</el-button>
          </el-form-item>

          <el-form-item label="">
            <div class="flex items-center">
              <div>{{ i18n.t('has_account') }}？</div>
              <el-button type="primary" @click="showLoginDialog" link>{{ i18n.t('login') }}</el-button>
            </div>
          </el-form-item>
        </el-form>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import vue3GoogleLogin from 'vue3-google-login'
import { ElMessage } from 'element-plus'
export default {
  props: ['i18n'],

  data() {
    return {
      dialogVisible: false,
      dialogType: 'login',
      dialogTitle: this.i18n.t('login'),
      dialogWidth: '400px',
      errorMessage: '',
      loginForm: {
        email: '',
        password: ''
      },
      registerForm: {
        email: '',
        password: '',
        password_confirmation: '',
        validation_code: '',
      },
      countDown: 0,
      idConfiguration: {
        client_id: '847800058259-vcbtk94sh1r4hfpb8f17mn6eo53gsguq.apps.googleusercontent.com'
      }
    };
  },

  components: {
    vue3GoogleLogin,
  },

  computed: {
    canSendValidationCode() {
      if (!this.isEmailValid(this.registerForm.email) || this.countDown > 0) return false

      return true
    },

    sendValidationCodeText() {
      if (this.countDown > 0) return `${this.i18n.t('already_sent')}（${this.countDown}）`

      return this.i18n.t('send_validation_code')
    }
  },

  methods: {
    showLoginDialog() {
      this.dialogVisible = true;
      this.dialogType = 'login';
      this.dialogTitle = this.i18n.t('login');
      this.errorMessage = ''
    },

    showRegisterDialog() {
      this.dialogVisible = true;
      this.dialogType = 'register';
      this.dialogTitle = this.i18n.t('sign_up');
      this.errorMessage = ''
    },

    resetForm() {
      if (this.dialogType === 'login') {
        this.$refs.loginForm.resetFields();
      } else if (this.dialogType === 'register') {
        this.$refs.registerForm.resetFields();
      }
    },

    cancelDialog() {
      this.dialogVisible = false
      this.resetForm()
    },

    async callback(response) {
      const res = await fetch('/google_auth', {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: this.requestHeader(),
        body: JSON.stringify(response), // body data type must match "Content-Type" header
      });
      const data = await res.json()
      if (data.code === 200) {
        window.location.reload()
      } else {
        this.dialogVisible = false
        ElMessage.error(data.message)
      }
    },

    async login() {
      // 执行登录逻辑
      const response = await fetch('/sign_in', {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: this.requestHeader(),
        body: JSON.stringify(this.loginForm), // body data type must match "Content-Type" header
      });
      const data = await response.json(); // parses JSON response into native JavaScript objects
      if (data.code === 200) {
        window.location.reload()
      } else {
        this.errorMessage = data.message
      }
    },

    async register() {
      // 执行注册逻辑
      const response = await fetch('/sign_up', {
        method: "POST", // *GET, POST, PUT, DELETE, etc.
        headers: this.requestHeader(),
        body: JSON.stringify(this.registerForm), // body data type must match "Content-Type" header
      });
      const data = await response.json(); // parses JSON response into native JavaScript objects
      if (data.code === 200) {
        window.location.reload()
      } else {
        this.errorMessage = data.message
      }
    },

    async sendValidationCode() {
      await fetch(`/users/send_validation_code?email=${this.registerForm.email}`)
      this.countDown = 60
      const interval = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown -= 1
        } else {
          clearInterval(interval)
        }
      }, 1000)
    },

    isEmailValid(email) {
      if (!email) return false

      const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      return reg.test(email)
    },

    requestHeader() {
      const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content")
      const headers = {
        "Content-Type": "application/json",
        "Accept": 'application/json',
        'X-CSRF-Token': csrf,
      }
      return headers
    }
  }
};
</script>
