import { createApp } from 'vue/dist/vue.esm-bundler';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { ElMessage, ElMessageBox } from 'element-plus'
import Login from '/components/login.vue'
import Navbar from '/components/navBar.vue'
import OrderContent from '/components/order.vue'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import vue3GoogleLogin from 'vue3-google-login'

import { I18n } from "i18n-js";
import cn from "../locale/cn.json";
import en from "../locale/en.json";
  
const app = createApp({  
  data() {  
    return {  
      course: 'Intro to Vue 3 and Rails',
      name: '',
      searchType: 'username',
      isSearchBusy: false,
      isDownloadBusy: false,
      boards: [],
      allChecked: true,
      isIndeterminate: false,
      selectedBoards: [],
      isPaid: '',
      userInfo: null,
      free_download_count_every_day: 0,
      last_search: '',
      locale: 'en',
      i18n: new I18n({}),
      upload_to_oss: false,
      downloadTypes: ['image'],
    }  
  },

  computed: {
    placeholder () {
      return this.i18n.t('input_username')
    },

    searchText() {
      return this.isSearchBusy ? this.i18n.t('wait') : this.i18n.t('search')
    },

    downloadBoards() {
      return this.boards.filter((item) => {
        return this.selectedBoards.indexOf(item.id) >= 0
      })
    },

    boardsData() {
      return this.downloadBoards.map((item) => {
        return {id: item.id, name: item.name}
      })
    },

    canDownloadVideo() {
      return this.userInfo?.can_download_video
    },

    selectedPinsCount () {
      return this.downloadBoards.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0);
    },
  },

  methods: {
    async search() {
      const searchName = this.name.trim()
      if (this.isSearchBusy || searchName === '' || searchName === this.last_search) return

      if (searchName.indexOf("http") === 0) {
        ElMessageBox.confirm(this.i18n.t('not_url'))
        return
      } else if (searchName.indexOf("/") >= 0) {
        ElMessageBox.confirm(this.i18n.t('no_slash'))
        return
      } else if (searchName.indexOf(" ") >= 0) {
        ElMessageBox.confirm(this.i18n.t('not_nickname'))
        return
      }

      try {
        this.isSearchBusy = true
        this.last_search = searchName
        const res = await fetch(`/pinterest/search?name=${this.name}`)
        this.boards = await res.json()
        this.selectedBoards = this.boards.map((item) => item.id)
      } finally {
        setTimeout(() => {
          this.isSearchBusy = false
        }, 1000)
      }
    },

    getUserInfo() {
      const node = document.getElementById('data-store')
      if (!node) return
  
      const data = node.dataset.store
      if (!data) return
  
      const json = JSON.parse(data)
      this.userInfo = json.user_info
      this.free_download_count_every_day = json.free_download_count_every_day
      this.locale = json.locale
      if (this.userInfo && this.userInfo.can_upload_to_oss) {
        this.upload_to_oss = true
      }
    },

    async download() {
      if (!this.userInfo) {
        ElMessage.error(this.i18n.t('login_to_download'))
        this.showLoginDialog()
        // ElMessageBox.confirm(this.i18n.t('login_to_download'))
        // .then(() => {
        //   this.showLoginDialog()
        //   done()
        // })
        // .catch(() => {
        //   this.showLoginDialog()
        // })
        return
      }
      if (this.boards.length === 0 || this.isDownloadBusy) return
      if (this.userInfo.download_count === 0) {
        ElMessage.error(this.i18n.t('zero_download_count'))
        return
      }

      if (this.userInfo.download_count < this.selectedPinsCount) {
        ElMessageBox.confirm(
          this.i18n.t('not_enough_download_count_for_selected_boards', {count1: this.selectedPinsCount, count2: this.userInfo.download_count}),
        {
          confirmButtonText: this.i18n.t('download'),
          cancelButtonText: this.i18n.t('buy'),
          distinguishCancelAndClose: true,
          type: 'warning',
        }
      )
        .then(async () => {
          await this.downloadPins()
        })
        .catch((action) => {
          console.log('action is: ', action)
          if (action !== 'cancel') return

          ElMessageBox.confirm(
            this.i18n.t('select_pay_method'),
            {
              confirmButtonText: '支付宝',
              // cancelButtonText: 'Paypal',
              cancelButtonText: 'Cancel',
              distinguishCancelAndClose: true,
              type: 'info',
            }
          )
            .then(() => {
              window.location.href = '/products?pay_type=alipay'
            })
            .catch((action) => {
              if (action !== 'cancel') return
              
              // window.location.href = '/products?pay_type=paypal'
            })
        })
        return
      }

      await this.downloadPins()
    },

    async downloadPins() {
      try {
        this.isDownloadBusy = true
        const url = '/pinterest/download'
        const data = { name: this.name, boards: this.boardsData, upload_to_oss: this.upload_to_oss, download_types: this.downloadTypes }
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content")
        const res = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          // mode: "cors", // no-cors, *cors, same-origin
          // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "include", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            'X-CSRF-Token': csrf,
            'Accept': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          // redirect: "follow", // manual, *follow, error
          // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(data), // body data type must match "Content-Type" header
        });
        const json = await res.json()
        if (json.code === 400) {
          ElMessage.error(this.i18n.t(json.message))
        } else if (json.code === 401) {
          ElMessage.error(this.i18n.t('login_to_download'))
        } else if (json.code === 403) {
          ElMessage.error(this.i18n.t('zero_download_count'))
        } else if (json.code === 200) {
          this.$refs.navbar.shine()
          ElMessage({
            message: this.i18n.t('task_submitted'),
            dangerouslyUseHTMLString: true,
            type: 'success',
            duration: 10000,
            showClose: true,
            grouping: true
          })
          window.location.href = '/download_tasks'
        } else {
          ElMessage.error(this.i18n.t('server_error'))
        }
      } finally {
        this.isDownloadBusy = false
        this.boards = []
        this.selectedBoards = []
        this.name = ''
        this.allChecked = true
        this.isIndeterminate = false
      }
    },

    handleCheckAllChange() {
      this.selectedBoards = this.boards.length === this.selectedBoards.length  ? [] : this.boards.map((item) => item.id)
      this.isIndeterminate = false
    },

    handleSelectedBoardsChange() {
      if (this.boards.length == this.selectedBoards.length || this.selectedBoards.length == 0) {
        this.isIndeterminate = false
        if (this.boards.length == this.selectedBoards.length) {
          this.allChecked = true
        }
      } else {
        this.isIndeterminate = true
        this.allChecked = false
      }
    },

    handleSelectionChange(val) {
      console.log('val is: ', val)
      this.selectedBoards = val
    },

    handleSelectedChange(val) {
      console.log("val in handleSelectedChange: ", val)

    },

    addQqGroup() {
      ElMessageBox.alert(
        '<div><span>QQ: </span><strong><i>877719775</i></strong><div><div><span>答案: </span><strong><i>ttsaver.top</i></strong></div>',
        '加入 QQ 交流群',
        {
          dangerouslyUseHTMLString: true,
        }
      )
    },

    showLoginDialog() {
      this.$refs.loginDialog.showLoginDialog()
    },

    setI18n() {
      const i18n = new I18n({});
      i18n.store(en);
      i18n.store(cn);
      i18n.locale = this.locale
      this.i18n = i18n
    },
  },

  mounted() {
    if (location.search.indexOf("order_paid=1") >= 1) {
      this.isPaid = this.i18n.t('paid_succeeded')
    }
    this.getUserInfo()
    this.setI18n()
  }
})

app.use(ElementPlus)
app.component('Login', Login) 
app.component('navbar', Navbar)
app.component('Ordercontent', OrderContent)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(vue3GoogleLogin, {
  clientId: '847800058259-vcbtk94sh1r4hfpb8f17mn6eo53gsguq.apps.googleusercontent.com'
})
app.mount('#app');  